import { FormError } from "@/components/blocks/form/error";
import type { Activity } from "@lib/activity";
import type { CustomHTMLBlock } from "@lib/config/types";
import {
  type FontFamily,
  resolveFontFamilyVariables,
  textBlockWidth,
} from "@lib/utils";
import { type CSSProperties, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { useEvent } from "react-use";
import { api } from "@lib/client";
import { logger } from "@lib/logger.client";
import type { Ticket } from "@lib/ticket";

type CustomHTMLProps = CustomHTMLBlock & {
  activity: Activity;
  ticket?: Ticket;
};

type CSSVars = CSSProperties & { "--anchor-color": string };

export function CustomHTML(props: CustomHTMLProps) {
  const { setValue } = useFormContext();
  const { custom_html } = props;

  const fontFamily = custom_html.font as FontFamily;

  const containerRef = useRef<HTMLDivElement>(null);
  useEvent(
    "message",
    (event: { data: { tag: string; data: unknown }; origin: string }) => {
      const tag = event.data.tag;

      switch (tag) {
        case "SelectProduct":
          if (
            custom_html.enable_product_selection ===
            "product_selection_disabled"
          )
            break;

          const selectProductEvent = (
            event as { data: { tag: "SelectProduct"; data: string } }
          ).data;
          setValue("order_allocation_ids", selectProductEvent.data);
          break;

        case "Resize":
          if (!containerRef.current) break;
          const iFrame = containerRef.current?.children[0] as HTMLIFrameElement;

          if (!iFrame) break;
          const resizeEvent = (
            event as { data: { tag: "Resize"; data: number } }
          ).data;

          // Extra 30px required to reach scrollHeight
          iFrame.height = `${resizeEvent.data + 30}px`;
          break;

        case "CustomQuestion":
          // We'll trust that the data coming from the iframe matches the expected custom question metadata
          const customQuestionEvent = (event as { data: CustomQuestionEvent })
            .data;

          setValue(
            `embedded-question:${customQuestionEvent.data.internal_id}`,
            JSON.stringify(customQuestionEvent.data),
          );
          break;

        case "RequestProducts":
          if (frames.length === 0) break;

          for (let i = 0; i < frames.length; i++) {
            frames[i]?.postMessage(
              { tag: "products", data: props.activity.products },
              "*",
            );
          }
          break;

        case "Track":
          const trackEvent = (
            event as {
              data: { tag: "Track"; data: { event: string; app: string } };
            }
          ).data;

          if (!trackEvent.data.event) {
            logger.error("No event provided for tracking");
            break;
          }

          if (!trackEvent.data.app) {
            logger.error("No app provided for tracking");
            break;
          }

          void api.track.$post({
            json: {
              encodedActivityId: props.activity.encodedId,
              ...trackEvent.data,
            },
          });
          break;

        // This is only needed for a VTO implementation.
        // We are adding restrictions to the origin of the event
        // and the target origin for the messages between journey and the VTO iframe.

        case "RequestTicketEmail":
          if (event.origin !== "https://maybelline-vto.us.sopost.com") break;
          if (frames.length === 0) break;

          for (let i = 0; i < frames.length; i++) {
            frames[i]?.postMessage(
              { tag: "ticketEmail", data: props.ticket?.address?.email },
              "https://maybelline-vto.us.sopost.com",
            );
          }
          break;
        case "RequestLeadId":
          if (event.origin !== "https://maybelline-vto.us.sopost.com") break;
          if (frames.length === 0) break;
          for (let i = 0; i < frames.length; i++) {
            frames[i]?.postMessage(
              { tag: "leadId", data: props.ticket?.user_id },
              "https://maybelline-vto.us.sopost.com",
            );
          }
          break;
      }
    },
  );

  return (
    <div
      data-block-type="Custom HTML"
      className="[&_a]:text-[var(--anchor-color)] mx-auto"
      style={
        {
          width: textBlockWidth(custom_html.width),
          "--anchor-color": custom_html.hyperlink_color,
          ...resolveFontFamilyVariables(
            "--text",
            fontFamily,
            custom_html.font_properties,
          ),
        } as CSSVars
      }
    >
      <div
        ref={containerRef}
        dangerouslySetInnerHTML={{ __html: custom_html.custom_html }}
        style={{ color: custom_html.color }}
        className="font-[family-name:--text-font-name] font-[--text-font-weight]"
      ></div>
      {custom_html.enable_product_selection !== "product_selection_disabled" ? (
        <FormError name="order_allocation_ids" className="mt-2 text-center" />
      ) : null}
    </div>
  );
}

type CustomQuestionEvent = {
  tag: "CustomQuestion";
  data: {
    type: "multiple_choice";
    answer: { answer: string; external_id: string | null; internal_id: string };
    question: string;
    external_id: string | null;
    internal_id: string;
  };
};
