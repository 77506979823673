import { Landing } from "@/components/pages/landing";
import type { Activity } from "@lib/activity";
import type { Ticket } from "@lib/ticket";
import type { Config } from "@lib/config/utils";
import { type GenericLandingPage } from "@lib/config/types";
import { createRoute } from "@tanstack/react-router";
import { Route as rootRoute, getContext } from "./root";
import { usePageTracking } from "@/lib/tracking";

export type CompleteProps = {
  activity: Activity;
  config: Config;
  encodedActivityId: string;
  ticket?: Ticket;
};

export const Route = createRoute({
  getParentRoute: () => rootRoute,
  path: "/flow/$encodedActivityId/complete",
  loader: getContext<CompleteProps>,
  component: function Page() {
    const props = Route.useLoaderData();
    usePageTracking(props.encodedActivityId, {
      type: "completion",
      number: 2,
    });
    return <CompletionPage {...props} />;
  },
});

export const DoubleOptinRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/flow/double_optin/$encodedActivityId/$encodedConsentId/complete",
  loader: getContext<CompleteProps>,
  component: function Page() {
    const props = DoubleOptinRoute.useLoaderData();
    usePageTracking(props.encodedActivityId, {
      type: "completion",
      number: 2,
    });
    return <CompletionPage {...props} />;
  },
});

export function CompletionPage({ activity, config, ticket }: CompleteProps) {
  return (
    <Landing
      activity={activity}
      config={config as GenericLandingPage}
      ticket={ticket}
      page="completion"
    />
  );
}
