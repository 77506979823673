import { CallToAction } from "@/components/blocks/call-to-action-v2";
import { Consents } from "@/components/blocks/consents-v2";
import { CustomHTML } from "@/components/blocks/custom-html";
import { CustomQuestions } from "@/components/blocks/custom-questions-v2";
import { Form } from "@/components/blocks/form-v2";
import { Gifting } from "@/components/blocks/form/gifting";
import { Image } from "@/components/blocks/image-v2";
import { Logo } from "@/components/blocks/logo-v2";
import { Pointer } from "@/components/blocks/pointer-v2";
import { ProductSelect } from "@/components/blocks/product-select-v2";
import { Separator } from "@/components/blocks/separator";
import { SubmitButton } from "@/components/blocks/submit-button-v2";
import { Text } from "@/components/blocks/text-v2";
import { Title } from "@/components/blocks/title-v2";
import { CallToAction as DEPRECATED_CallToAction } from "@/components/deprecated/call-to-action";
import { Consents as DEPRECATED_Consents } from "@/components/deprecated/consents";
import { CustomQuestions as DEPRECATED_CustomQuestions } from "@/components/deprecated/custom-questions";
import { Form as DEPRECATED_Form } from "@/components/deprecated/form";
import { Image as DEPRECATED_Image } from "@/components/deprecated/image";
import { Logo as DEPRECATED_Logo } from "@/components/deprecated/logo";
import { Pointer as DEPRECATED_Pointer } from "@/components/deprecated/pointer";
import { ProductSelect as DEPRECATED_ProductSelect } from "@/components/deprecated/product-select";
import { SubmitButton as DEPRECATED_SubmitButton } from "@/components/deprecated/submit-button";
import { Text as DEPRECATED_Text } from "@/components/deprecated/text";
import { Title as DEPRECATED_Title } from "@/components/deprecated/title";
import { SyndicatorQuestions } from "@/components/blocks/syndicator-questions-v2";
import { ProgressButton } from "@/components/blocks/progress-button-v2";
import { ShopNowButton } from "@/components/blocks/shop-now-button-v2";
import { ImageUpload } from "@/components/blocks/image-upload";
import type { Activity, ActivityFlowConsents } from "@lib/activity";

import type {
  DoubleOptinPage,
  FormBlock,
  GenericLandingPage,
  GenericReview,
  LandingPageBlock,
  ReviewPageBlock,
} from "@lib/config/types";
import type { Ticket } from "@lib/ticket";
import { blockWidth, borderRadius, hsla } from "@lib/utils";
import type { FlowType } from "./blocks";

export type BlockProps = {
  activity: Activity;
  block: LandingPageBlock | ReviewPageBlock;
  config: GenericLandingPage | DoubleOptinPage | GenericReview;
  ticket?: Ticket;
  flowConsents?: ActivityFlowConsents;
  flowType?: FlowType;
};

export function Block(props: BlockProps) {
  /** Syndicator questions v1 & v2 uses 'block_style', not 'style' like every other block, so we need to handle that case before accessing any properties */
  const style =
    props.block.type === "syndicator_questions_v2" ||
    props.block.type === "syndicator_questions"
      ? props.block.values.block_style
      : props.block.values.style;

  return (
    <div
      style={{
        width: blockWidth(style.width),
        backgroundColor:
          style.callout === "global_background_colour"
            ? "var(--block-background)"
            : hsla(style.callout.colour),
        paddingTop: style.padding.top,
        paddingRight: style.padding.right,
        paddingBottom: style.padding.bottom,
        paddingLeft: style.padding.left,
        marginTop: style.margin.top,
        marginRight: style.margin.right,
        marginBottom: style.margin.bottom,
        marginLeft: style.margin.left,
        borderRadius: borderRadius(style.corner_radius),
        zIndex: style.z_index === 0 ? "auto" : style.z_index,
      }}
    >
      <BlockFactory {...props} />
    </div>
  );
}

function BlockFactory({ block, config, ...props }: BlockProps) {
  switch (block.type) {
    case "call_to_action":
      return <DEPRECATED_CallToAction {...block.values} />;
    case "call_to_action_v2":
      return <CallToAction {...block.values} />;
    case "consents":
      return <DEPRECATED_Consents {...block.values} {...props} />;
    case "consents_v2":
      return (
        <Consents {...block.values} {...props} flowType={props.flowType} />
      );
    case "custom_questions":
      return (
        <DEPRECATED_CustomQuestions
          {...block.values}
          ticket={props.ticket}
          flowType={props.flowType}
        />
      );
    case "custom_questions_v2":
      return (
        <CustomQuestions
          {...block.values}
          ticket={props.ticket}
          flowType={props.flowType}
        />
      );
    case "form":
      return <DEPRECATED_Form {...(block.values as FormBlock)} {...props} />;
    case "form_v2":
      return <Form {...block.values} {...props} />;
    case "gifting":
      return <Gifting {...block.values} {...props} />;
    case "image":
      return <DEPRECATED_Image {...block.values} />;
    case "image_v2":
      return <Image {...block.values} />;
    case "logo_bar":
      return <DEPRECATED_Logo config={config} {...block.values} {...props} />;
    case "logo_bar_v2":
      return <Logo {...block.values} {...props} />;
    case "pointer":
      return <DEPRECATED_Pointer {...block.values} />;
    case "pointer_v2":
      return <Pointer {...block.values} />;
    case "product_selection":
      return <DEPRECATED_ProductSelect {...block.values} {...props} />;
    case "product_selection_v2":
      return <ProductSelect {...block.values} {...props} />;
    case "separator":
      return <Separator {...block.values} />;
    case "submit_button":
      return <DEPRECATED_SubmitButton {...block.values} />;
    case "submit_button_v2":
      return <SubmitButton {...block.values} />;
    case "text":
      return <DEPRECATED_Text {...block.values} />;
    case "text_v2":
      return <Text {...block.values} />;
    case "title":
      return <DEPRECATED_Title {...block.values} />;
    case "title_v2":
      return <Title {...block.values} />;
    case "custom_html":
      return (
        <CustomHTML
          activity={props.activity}
          ticket={props.ticket}
          {...block.values}
        />
      );
    case "syndicator_questions_v2":
      return <SyndicatorQuestions {...block.values} />;
    case "progress_button_v2":
      return <ProgressButton {...block.values} />;
    case "shop_now_button_v2":
      return <ShopNowButton {...block.values} />;
    case "image_upload":
      return <ImageUpload {...block.values} {...props} />;
    default:
      throw new Error(`Could not find a component to render for ${block.type}`);
  }
}
