import { Button } from "@/components/ui/button";
import { useMutation } from "@tanstack/react-query";
import { api } from "@lib/client";
import { logger } from "@lib/logger.client";
import { useTranslations } from "@/components/context/translations";
import { createRoute, useNavigate } from "@tanstack/react-router";
import { getContext, Route as rootRoute } from "./root";
import { usePageTracking } from "@/lib/tracking";

export type UnsubscribeProps = {
  encodedActivityId: string;
  orderId: string;
};

export const Route = createRoute({
  getParentRoute: () => rootRoute,
  path: "/flow/unsubscribe/$shortOrderId",
  loader: () => getContext<UnsubscribeProps>(),
  component: function Page() {
    const props = Route.useLoaderData();
    const { shortOrderId } = Route.useParams();
    return <UnsubscribePage {...props} shortOrderId={shortOrderId} />;
  },
});

export function UnsubscribePage(
  props: UnsubscribeProps & {
    shortOrderId: string;
  },
) {
  const navigate = useNavigate();
  const t = useTranslations();

  usePageTracking(props.encodedActivityId, {
    type: "unsubscribe",
    number: 1,
  });

  const { mutateAsync: onSubmit, isPending: loading } = useMutation({
    mutationFn: async () => {
      const unsubscribeResponse = await api.unsubscribe.$post({
        json: {
          orderId: props.orderId,
        },
      });

      const unsubscribeResult = await unsubscribeResponse.json();

      if (!unsubscribeResult.success) {
        throw new Error(unsubscribeResult.error);
      }

      return unsubscribeResult;
    },
    onError: (error) => {
      logger.error(error, "Error handling the mutation");
    },
    onSuccess: async (data) => {
      if (data.success) {
        await navigate({
          to: "/flow/unsubscribe/$shortOrderId/unsubscribed",
          params: { shortOrderId: props.shortOrderId },
        });
      }
    },
  });

  return (
    <div className="text-center shadow-lg shadow-gray-300 border border-gray-100 rounded-lg w-[363px] mx-auto my-20">
      <div className="flex flex-col items-center justify-between p-6">
        <h1 className="text-2xl font-semibold pb-4 leading-[33.6px]">
          {t("Unsubscribe")}
        </h1>
        <p className="pb-4 text-base font-normal">
          {t(
            "Click the button below to unsubscribe from emails relating to this campaign.",
          )}
        </p>
        <Button
          className="bg-gray-100 text-black shadow-lg shadow-gray-300 border border-gray-300 w-[314px] h-[42px] text-base font-normal transition-all duration-300 hover:border-2 hover:bg-gray-100"
          onClick={() => {
            void onSubmit();
          }}
          disabled={loading}
        >
          {loading ? t("Unsubscribing...") : t("Unsubscribe")}
        </Button>
      </div>
    </div>
  );
}
