import { ReviewForm } from "@/components/forms/review/form";
import { Review } from "@/components/pages/review";
import type { Activity } from "@lib/activity";
import type { Ticket } from "@lib/ticket";
import type { GenericReview } from "@lib/config/types";
import { type OrderItemDetails } from "@lib/review";
import { createRoute, notFound } from "@tanstack/react-router";
import { Route as rootRoute, getContext } from "./root";
import { z } from "zod";

export type ReviewProps = {
  activity: Activity;
  config: GenericReview;
  ticket?: Ticket;
  encodedActivityId: string;
  orderItemDetails?: OrderItemDetails;
  currentStep?: string;
};

const stepSchema = (lastPage: number) =>
  z
    .union([
      z.literal("").transform(() => 0),
      z.literal("reviewed").transform(() => lastPage),
      z.coerce.number().min(0).max(lastPage),
    ])
    .transform((v) => v.toString());

export const Route = createRoute({
  getParentRoute: () => rootRoute,
  path: "/flow/review/$encodedActivityId/$",
  loader: ({ params }) => {
    const data = getContext<ReviewProps>();
    const lastPage = data.config.pages.length - 1;
    const step = stepSchema(lastPage).safeParse(params._splat);
    if (step.error) {
      throw notFound();
    }
    const currentStep = step.data;

    return {
      ...data,
      currentStep,
    };
  },
  component: function Page() {
    const props = Route.useLoaderData();
    return <ReviewPage {...props} />;
  },
});

export function ReviewPage({
  activity,
  config,
  ticket,
  encodedActivityId,
  orderItemDetails,
  currentStep,
}: ReviewProps) {
  return (
    <ReviewForm
      encodedActivityId={encodedActivityId}
      ticket={ticket}
      config={config}
      currentStep={currentStep ?? "0"}
      orderItemDetails={orderItemDetails}
    >
      <Review
        activity={activity}
        config={config}
        ticket={ticket}
        flowConsents={activity.reviewConsents}
        orderItemDetails={orderItemDetails}
        encodedActivityId={encodedActivityId}
        page={currentStep ?? "0"}
      />
    </ReviewForm>
  );
}
