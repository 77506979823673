import type { Activity } from "@lib/activity";
import type { GenericReview, GenericReviewPages } from "./config/eval";
import { type Config } from "./config/utils";
import { type Values } from "./utils";

export type OrderItemDetails =
  | {
      name: string | null;
      productId: string | null;
      id: string | null;
    }
  | undefined;

/** Return the review page based on an index */
export function resolveReviewConfig(
  config: GenericReview,
  pageIndex: string,
  activity: Activity,
) {
  const index = Number(pageIndex);

  // This wont work for single products
  const products =
    activity?.products.type === "single"
      ? [activity?.products.product]
      : activity?.products.product.products;

  const replacedValues = {
    product: products ? products[0].name : "{{product}}",
  };

  const replacedConfig = replaceValues(config, replacedValues);

  const pages = replacedConfig.pages;

  if (index < 0 || index >= pages.length) {
    // NOTE: this works on bundles because we stay on the same page,
    // and the backend ensures that we have a different product to review.
    throw new Error("Invalid page index or review pages object structure");
  }
  const pageObject = pages[index];
  const pageKeys = Object.keys(pageObject);

  if (pageKeys.length === 0) {
    throw new Error("Unsupported page structure");
  }

  const pageKey = pageKeys[0] as keyof typeof pageObject;
  return {
    page: mapName(pageKey),
    blocks: pageObject[pageKey],
  };
}

function mapName(page: "standard_page" | "per_product_page" | "thank_you") {
  switch (page) {
    case "standard_page":
      return "feedback";
    case "per_product_page":
      return "product";
    default:
      return page;
  }
}

export function resolveReviewPage(
  config: GenericReview,
  pageIndex: string,
  activity: Activity,
) {
  return resolveReviewConfig(config, pageIndex, activity).blocks;
}

/** Maps review question config names to their expected names for submitting */
function resolveQuestionName(name: string) {
  switch (name) {
    case "review_title":
      return "title";
    case "review":
      return "reviewText";
    default:
      return name;
  }
}

/** Formats any review questions into the expected structure for submitting */
export function formatReviewQs(qs: Values) {
  return Object.entries(qs)
    .filter(
      ([key]) =>
        !key.includes("consent") &&
        !key.includes("fingerprint") &&
        !key.includes("blackBox") &&
        !key.includes("ugc"),
    )
    .map(([key, val]) => {
      const strippedValue = String(val)
        .replace(/<\/?[^>]+(>|$)/g, "")
        .trim();

      const trimmedKey = key.substring(key.indexOf(":") + 1).trim();

      const includeInSyndication =
        key.includes("syndicator-question") ||
        key.includes("custom-question:rating");

      return {
        questionName: resolveQuestionName(trimmedKey),
        questionAnswer: key.includes("userGeneratedContent")
          ? Array.isArray(val)
            ? val.map((v) => String(v).trim())
            : [strippedValue]
          : strippedValue,
        includeInSyndication: includeInSyndication,
      };
    })
    .filter((obj) => obj.questionName !== "nickname");
}

/** Formats any review order questions into the expected structure for submitting */
export function formatReviewOrderQs(qs: Values) {
  return Object.entries(qs)
    .filter(
      ([key]) =>
        !key.includes("blackBox") &&
        !key.includes("consent") &&
        !key.includes("fingerprint") &&
        !key.includes("syndicator"),
    )
    .map(([key, val]) => {
      const strippedValue = String(val)
        .replace(/<\/?[^>]+(>|$)/g, "")
        .trim();

      const trimmedKey = key.substring(key.indexOf(":") + 1).trim();

      return {
        questionName: resolveQuestionName(trimmedKey),
        questionAnswer: strippedValue,
        includeInSyndication: false,
      };
    });
}

/** Reduces the config pages to an array of strings */
export function reviewStepArray(data: GenericReviewPages) {
  return data.reduce((acc: string[], obj) => {
    const key = Object.keys(obj)[0];
    return [...acc, key];
  }, []);
}

/** Maps any agreed consents to an array of consent id's */
export function gatherReviewConsents(data: Values) {
  return Object.entries(data)
    .filter(([key, val]) => key.startsWith("consent:") && val === true)
    .map(([key]) => key.substring(key.indexOf(":") + 1));
}

export type Consent = {
  id: string;
  type: string;
  handler: string;
  required: boolean;
  doubleOptIn: boolean;
  label: string;
  disclaimer: string;
  error: string;
};

/** Find any activity consents that match an id and bring back thier id and type */
export function findMatchingConsents(
  consents: Consent[],
  ids: string[],
): { id: string; type: string }[] {
  return consents
    .filter((consent) => ids.includes(consent.id))
    .map((consent) => ({
      id: consent.id,
      type: consent.type,
    }));
}

/** Get the value of a syndicator consent */
export function getSyndicatorConsent(data: Values) {
  if (data === null) {
    return false;
  }

  for (const key in data) {
    if (key.includes("syndicator-consent:")) {
      const value = data[key];

      if (typeof value === "boolean") {
        return value;
      } else if (typeof value === "string") {
        return value.toLowerCase() === "true";
      } else if (typeof value === "number") {
        return value !== 0;
      }
    }
  }

  return false;
}

type Replacements = Record<string, string>;

export function replaceValues<TConfig extends Config>(
  config: TConfig,
  replacements: Replacements,
): TConfig {
  const replaceString = (str: string): string => {
    return str.replace(
      /{{(\w+)}}/g,
      (_, key) => replacements[key as keyof Replacements] || "",
    );
  };

  const replace = <U>(obj: U): U => {
    if (typeof obj === "string") {
      return replaceString(obj) as unknown as U;
    } else if (Array.isArray(obj)) {
      return obj.map((item) => replace(item) as U) as unknown as U;
    } else if (typeof obj === "object" && obj !== null) {
      const newObj = {} as { [K in keyof U]: U[K] };
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          newObj[key] = replace(obj[key]);
        }
      }
      return newObj as U;
    }
    return obj;
  };

  return replace(config);
}

type ReviewCompletionStatus = "none" | "some" | "all";
type OrderCompletionStatus = "not_started" | "in_progress" | "complete";
type ReviewResponse = {
  reviews: Array<{ id: string | null } | null> | null;
};

export type ReviewStatus = {
  productReviewStatus: ReviewCompletionStatus;
  orderReviewStatus: OrderCompletionStatus;
};

export function productReviewStatus(
  reviews: (ReviewResponse | null)[],
  productCount: number,
) {
  if (!reviews || reviews.length === 0) {
    return "none";
  }
  if (reviews.length === productCount) {
    return "all";
  }
  return "some";
}
