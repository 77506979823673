import { api } from "@lib/client";
import type { Event } from "@lib/tracking.server";
import { useCallback, useEffect, useRef } from "react";

function useFirstInput(trackEvent: (event: string) => Promise<void>) {
  const alreadyTracked = useRef(false);

  // Track input interactions
  useEffect(() => {
    if (alreadyTracked.current) return;

    const handleInputInteraction = (e: MouseEvent | FocusEvent) => {
      const tagName = (e.target as HTMLElement)?.tagName;
      const role = (e.target as HTMLElement)?.role;
      // Check if the event target is an input element
      if (
        !alreadyTracked.current &&
        (tagName === "INPUT" ||
          tagName === "SELECT" ||
          tagName === "TEXTAREA" ||
          tagName === "BUTTON" ||
          role === "checkbox" ||
          role === "radio")
      ) {
        alreadyTracked.current = true;
        void trackEvent("interaction_form_start");
      }
    };
    // Listen for various interaction events
    document.addEventListener("click", handleInputInteraction);
    document.addEventListener("focus", handleInputInteraction, true);

    // Cleanup function
    return () => {
      document.removeEventListener("click", handleInputInteraction);
      document.removeEventListener("focus", handleInputInteraction, true);
    };
  }, [trackEvent, alreadyTracked.current]);
}

function usePageScroll(trackEvent: (event: string) => Promise<void>) {
  const alreadyTracked = useRef(false);

  useEffect(() => {
    if (alreadyTracked.current) return;

    const handleScroll = () => {
      if (alreadyTracked.current) return;
      alreadyTracked.current = true;
      void trackEvent("interaction_scroll_start");
    };

    // Listen for scroll events
    window.addEventListener("scroll", handleScroll);

    // Cleanup function
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [trackEvent, alreadyTracked.current]);
}

function usePageLoad(trackEvent: (event: string) => Promise<void>) {
  const alreadyTracked = useRef(false);

  useEffect(() => {
    if (alreadyTracked.current) return;

    alreadyTracked.current = true;
    void trackEvent("page_load");
  }, [trackEvent, alreadyTracked.current]);
}

function useAddressSearch(trackEvent: (event: string) => Promise<void>) {
  const alreadyTracked = useRef(false);

  // Track input interactions
  useEffect(() => {
    if (alreadyTracked.current) return;

    const handleInputInteraction = (e: MouseEvent | FocusEvent) => {
      const inputType = (e.target as HTMLInputElement)?.dataset.inputType;
      // Check if the event target is the address search field
      if (!alreadyTracked.current && inputType === "address-search") {
        alreadyTracked.current = true;
        void trackEvent("interaction_form_address_search_start");
      }
    };
    // Listen for various interaction events
    document.addEventListener("click", handleInputInteraction);
    document.addEventListener("focus", handleInputInteraction, true);

    // Cleanup function
    return () => {
      document.removeEventListener("click", handleInputInteraction);
      document.removeEventListener("focus", handleInputInteraction, true);
    };
  }, [trackEvent, alreadyTracked.current]);
}

export function usePageTracking(
  encodedActivityId: string,
  page: NonNullable<Event["page"]>,
) {
  const track = useCallback(
    async (event: string) => {
      await api.track.$post({
        json: {
          event,
          encodedActivityId,
          page,
        },
      });
    },
    [page],
  );

  useFirstInput(track);
  usePageScroll(track);
  usePageLoad(track);
  useAddressSearch(track);
}
