import type enGB from "@lib/dictionaries/en_GB.json";
import { logger } from "./logger.client";

export type Dictionary = Record<string, string>;

// Use base language keys as strict translation key enforcement
export type TranslationKey = keyof typeof enGB;

// Pull interpolated variables out into an object type to aid in TS autocomplete
type Params<T> = T extends `${string}{{${infer P}}}${infer R}`
  ? { [key in P]: string } & Params<R>
  : Record<string, string>;

const variablesInBracesRegex = /\{\{\s*(.*?)\s*\}\}/g;

export function getTranslations<Key extends string = TranslationKey>(
  dictionary: Record<Key, string>,
  alwaysShowKeys = false,
) {
  return function getTranslation(key: Key, replacements?: Params<Key>): string {
    const translation = dictionary[key];

    if (!translation || alwaysShowKeys) return key;

    if (!replacements) {
      if (variablesInBracesRegex.test(translation)) {
        logger.warn(`[i18n] Missing replacements on key: ${key}`);
      }
      return translation;
    }

    return translation.replaceAll(
      variablesInBracesRegex,
      (match: string, replacementKey: keyof Params<Key>) => {
        const replacement = replacements[replacementKey];

        if (replacement) {
          return replacement;
        } else {
          logger.warn(
            `[i18n] No replacement available for "${String(replacementKey)}" on key: ${key}`,
          );
          return match;
        }
      },
    );
  };
}
