import { FormField } from "@/components/blocks/form/field";
import type { Activity } from "@lib/activity";
import { extractBlocks, type FormBlockV2 } from "@lib/config/types";
import { fieldDTO } from "@lib/config/form";
import type { Ticket } from "@lib/ticket";
import {
  alignStyle,
  resolveFontFamilyVariables,
  textBlockWidth,
  type FontFamily,
} from "@lib/utils";
import { useState, type CSSProperties } from "react";
import { StyleProvider } from "@/components/blocks/form/style";

type FormProps = FormBlockV2 & {
  activity: Activity;
  ticket?: Ticket;
};

type FormStyles = CSSProperties & {
  "--input-color": string;
  "--input-background": string;
  "--input-border-color": string;
  "--input-radius": string;
  "--input-font-family": string;
  "--input-font-weight": string;
  "--input-font-style": string;
  "--label-color": string;
  "--label-font-family": string;
  "--label-font-weight": string;
  "--label-font-style": string;
  "--territory-label-color": string;
  "--placeholder-color": string;
  "--field-border-size": string;
};

// INLINE_VALIDATION_OF_ADDRESSES_TEMP_CODE_START --
const isStandardAddressField = (fieldId: string) =>
  [
    "address1",
    "address2",
    "postcode",
    "town",
    "district",
    "delivery_notes",
  ].includes(fieldId);

/**
 * Returns true if:
 * 1. The addressFormTest is "on" for this activity
 * 2. The user is in the test group (50% chance)
 */
const getAddressSearchActive = (activity: Activity) => {
  const isPreview = window.location.pathname.startsWith("/flow/config-preview");
  // Always show standard form initially on builder
  if (isPreview) return false;

  if (activity.addressFormTest) {
    return Math.random() >= 0.5;
  }

  return null;
};

declare global {
  // eslint-disable-next-line no-var
  var addressSearchTestActive: boolean | null;
  // eslint-disable-next-line no-var
  var setAddressSearchSplitTest: (enabled: boolean) => void;
}
window.addressSearchTestActive = null;
// INLINE_VALIDATION_OF_ADDRESSES_TEMP_CODE_END --

export function Form({
  form,
  fields: formFields,
  ticket,
  activity,
}: FormProps) {
  // INLINE_VALIDATION_OF_ADDRESSES_TEMP_CODE_START --
  const [, triggerRerender] = useState({});
  window.setAddressSearchSplitTest = (enabled: boolean) => {
    window.addressSearchTestActive = enabled;
    triggerRerender({});
  };
  if (window.addressSearchTestActive === null) {
    window.addressSearchTestActive = getAddressSearchActive(activity);
  }
  // INLINE_VALIDATION_OF_ADDRESSES_TEMP_CODE_END --

  const fields = extractBlocks(formFields);
  const transformedFields = fields
    .map((field) => fieldDTO(field, ticket))
    .flat()
    // INLINE_VALIDATION_OF_ADDRESSES_TEMP_CODE_START --
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .filter((field: any) => {
      let keep = true;
      // Prevent standard address fields from showing
      // when test is active
      if (window.addressSearchTestActive) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        keep = !isStandardAddressField(field.id);
      } else {
        keep = field.id !== "address_search";
      }
      return keep;
    });
  // INLINE_VALIDATION_OF_ADDRESSES_TEMP_CODE_END --
  const style = getStyles(form);

  return (
    <StyleProvider style={style}>
      <section
        data-block-type="Form"
        className="grid gap-7"
        style={
          {
            maxWidth: textBlockWidth(form.width),
            ...alignStyle(form),
            ...style,
          } as FormStyles
        }
      >
        {transformedFields.map((field) => (
          <FormField key={field.name} activity={activity} {...field} />
        ))}
      </section>
    </StyleProvider>
  );
}

function getStyles(form: FormBlockV2["form"]) {
  const isPill =
    form.field_style.border.radius === 9999.0 ||
    form.field_style.border.radius === 99999.0;

  return {
    "--input-color": form.field_style.color,
    "--input-background": form.field_style.background_color,
    "--input-border-color": form.field_style.border.color,
    "--input-radius": `${isPill ? "9999em" : `min(${form.field_style.border.radius}%, 1.5625rem)`}`,
    ...resolveFontFamilyVariables(
      "--label",
      form.label_style.font as FontFamily,
      form.label_style.font_properties,
    ),
    "--label-color": form.label_style.color,
    ...resolveFontFamilyVariables(
      "--input",
      form.field_style.font as FontFamily,
      form.field_style.font_properties,
    ),
    "--placeholder-color": form.field_style.placeholder_color,
    "--field-border-size": `${form.field_style.border.width}px`,
  } as FormStyles;
}
