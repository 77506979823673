// INLINE_VALIDATION_OF_ADDRESSES_TEMP_CODE --
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import type { FormInputField } from "@lib/config/form";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import type {
  LoqateAddress,
  LoqateControl,
  LoqateGlobal,
} from "./loqate-types";
import type { Activity } from "@lib/activity";
import type { Event } from "@lib/tracking.server";
import { api } from "@lib/client";
import { useTranslations } from "@/components/context/translations";

type FormInputProps = FormInputField;

declare global {
  // eslint-disable-next-line no-var
  var pca: LoqateGlobal;
}

export function Address({ address }: { address: LoqateAddress }) {
  const { control, setValue } = useFormContext();

  useEffect(() => {
    setValue("address1", address.Line1);
    setValue("address2", address.Line2);
    setValue("address3", address.Line3);
    setValue("town", address.City);
    setValue("district", address.Province);
    setValue("postcode", address.PostalCode);
    setValue("addressSearchTestActive", window.addressSearchTestActive);
  }, [address]);
  const t = useTranslations();

  return (
    <div>
      <FormLabel
        style={{
          fontSize: "var(--label-font-size)",
          fontFamily: "var(--label-font-name, var(--font-family))",
          fontStyle: "var(--label-font-style, var(--font-style))",
          fontWeight: "var(--label-font-weight, var(--font-weight))",
          color: "var(--label-color)",
        }}
        dangerouslySetInnerHTML={{
          __html: t("Address"),
        }}
      />
      <div
        className="mt-4"
        style={{
          minBlockSize: "var(--input-block-size)",
          fontFamily: "var(--input-font-name, var(--font-family))",
          fontStyle: "var(--input-font-style, var(--font-style))",
          fontWeight: "var(--input-font-weight, var(--font-weight))",
          color: "var(--input-color)",
          backgroundColor: "var(--input-background)",
          borderTopLeftRadius:
            "var(--field-border-top-left-radius, var(--input-radius))",
          borderTopRightRadius:
            "var(--field-border-top-right-radius, var(--input-radius))",
          borderBottomRightRadius:
            "var(--field-border-bottom-right-radius, var(--input-radius))",
          borderBottomLeftRadius:
            "var(--field-border-bottom-left-radius, var(--input-radius))",
          border: "var(--field-border-size) solid var(--input-border-color)",
        }}
      >
        <div className="p-4 gap-2 flex flex-col">
          <>
            <FormField
              control={control}
              name="address1"
              render={() => <div>{address.Line1}</div>}
            />
            <FormField
              control={control}
              name="address2"
              render={() =>
                address.Line2 ? <div>{address.Line2}</div> : <></>
              }
            />
            {address.Line3 && <div>{address.Line3}</div>}
            <FormField
              control={control}
              name="town"
              render={() => <div>{address.City}</div>}
            />
            <FormField
              control={control}
              name="district"
              render={() => <div>{address.Province}</div>}
            />
            <FormField
              control={control}
              name="postcode"
              render={() => <div>{address.PostalCode}</div>}
            />
          </>
        </div>
      </div>
    </div>
  );
}

type Props = FormInputProps & {
  activity?: Activity;
};

function getLoqateLanguage(activity: Activity) {
  switch (activity.locale) {
    case "de_DE":
      return "DEU";
    case "fr_FR":
      return "FRA";
    case "en_US":
    case "es_US":
      return "USA";
    case "en_GB":
    default:
      return "GB";
  }
}

// This tracks the selection of an address from the Loqate API.
// So far the address search is only used on the landing page.
async function trackSelection(encodedActivityId: string) {
  const page: NonNullable<Event["page"]> = {
    type: "landing",
    number: 1,
  };

  await api.track.$post({
    json: {
      event: "interaction_form_address_selected",
      encodedActivityId,
      page,
    },
  });
}

export function AddressSearch({ ...props }: Props) {
  const { control } = useFormContext();
  const [address, setAddress] = useState<LoqateAddress | null>(null);
  const [input, setInput] = useState("");

  const { label, activity } = props;

  useEffect(() => {
    const loqateLanguage = getLoqateLanguage(activity!);
    // https://www.loqate.com/developers/guides/advanced-setup-guide/
    const fields = [{ element: "search", field: "" }];
    // We don't own this type and it's not worth reverse engineering it
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const control: LoqateControl = new pca.Address(fields, {
      key: "UF89-UU23-ZY59-AA96",
      simulateReactEvents: true,
      search: { countries: loqateLanguage },
      countries: { codesList: loqateLanguage },
      setCountryByIP: false,
      GeoLocationEnabled: false,
      GeoLocationRadius: 50,
      GeoLocationMaxItems: 10,
      bar: {
        visible: false,
        showCountry: false,
        showLogo: false,
        logoLink: false,
      },
    }) as LoqateControl;

    control.listen("populate", (address: LoqateAddress) => {
      setAddress(address);
      void trackSelection(activity!.encodedId);
      control.hide();
    });
    return () => control.destroy();
  }, []);

  return (
    <>
      <FormField
        control={control}
        // By hard-coding the field's name to address1, we can
        // re-use the validation for address1. This is a hack
        // and should be removed if we decide to run with
        // address search
        name={"address1"}
        defaultValue=""
        render={({ field }) => (
          <FormItem>
            <FormLabel
              style={{
                fontSize: "var(--label-font-size)",
                fontFamily: "var(--label-font-name, var(--font-family))",
                fontStyle: "var(--label-font-style, var(--font-style))",
                fontWeight: "var(--label-font-weight, var(--font-weight))",
                color: "var(--label-color)",
              }}
              dangerouslySetInnerHTML={{
                __html: label ?? "",
              }}
            />
            <FormControl>
              <Input
                {...field}
                {...props}
                onKeyDown={(e) => {
                  if (e.key === "Enter") e.preventDefault();
                }}
                value={input}
                onChange={(e) => setInput(e.target.value)}
                data-input-type="address-search"
                type="search"
                className="px-[1em] py-[0.5em] shadow-none"
                style={{
                  minBlockSize: "var(--input-block-size)",
                  fontFamily: "var(--input-font-name, var(--font-family))",
                  fontStyle: "var(--input-font-style, var(--font-style))",
                  fontWeight: "var(--input-font-weight, var(--font-weight))",
                  color: "var(--input-color)",
                }}
              />
            </FormControl>

            <FormMessage />
          </FormItem>
        )}
      />
      {address && <Address address={address} />}
    </>
  );
}
