import { createRootRoute, Outlet } from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import useSize from "@react-hook/size";
import { useEffect } from "react";

export const Route = createRootRoute({
  component: () => (
    <>
      <Outlet />
      <IFrameTools />
      {import.meta.env.DEV && <TanStackRouterDevtools />}
    </>
  ),
});

export function getContext<T>(): T {
  return (window as unknown as { __journey_context: T }).__journey_context;
}

function IFrameTools() {
  const isInIFrame = window.self !== window.top;
  return <>{isInIFrame ? <IFrameHeight /> : null} </>;
}

function IFrameHeight() {
  const [_, height] = useSize(document.body);
  useEffect(() => {
    window.parent.postMessage({ type: "iframeHeight", height }, "*");
  }, [height]);
  return <> </>;
}
