import { Blocks } from "@/components/blocks/blocks";
import { usePageTracking } from "@/lib/tracking";
import type { Activity, ActivityFlowConsents } from "@lib/activity";
import type { GenericReview } from "@lib/config/types";
import { applyStageBranding } from "@lib/landing";
import { type OrderItemDetails, resolveReviewConfig } from "@lib/review";
import { type Ticket } from "@lib/ticket";

type ReviewProps = {
  encodedActivityId: string;
  activity: Activity;
  config: GenericReview;
  page: string;
  ticket?: Ticket;
  orderItemDetails?: OrderItemDetails;
  flowConsents?: ActivityFlowConsents;
};

export function Review(props: ReviewProps) {
  const { blocks, page } = resolveReviewConfig(
    props.config,
    props.page,
    props.activity,
  );

  usePageTracking(props.encodedActivityId, {
    type: page,
    number: Number(props.page) + 1,
    product_id: props.orderItemDetails?.productId ?? "",
  });

  return (
    <div
      className="min-h-screen grid justify-items-center items-start content-start"
      style={applyStageBranding(props.config.branding)}
    >
      <Blocks blocks={blocks} {...props} flowType="review" />
    </div>
  );
}
