import { initSentry } from "./instrument";

import { ErrorBoundary } from "@sentry/react";
import ReactDOM from "react-dom/client";
import "./globals.css";
import { TranslationsProvider } from "@/components/context/translations";
import { CookiesProvider } from "react-cookie";
import { ReactQueryProvider } from "@/components/context/react-query";
import React from "react";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import { NotFound } from "./components/pages/404";

import { routeTree } from "./routes";
import type { Dictionary } from "@lib/i18n.client";
import { ServerError } from "./components/pages/5xx";

declare const dictionary: Dictionary;

// Create a new router instance
const router = createRouter({
  routeTree,
  defaultNotFoundComponent: NotFound,
});

initSentry(router);

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ErrorBoundary fallback={<ServerError />}>
      <CookiesProvider
        defaultSetOptions={{
          path: "/",
          secure: true,
          sameSite: "lax",
        }}
      >
        <TranslationsProvider dictionary={dictionary}>
          <ReactQueryProvider>
            <RouterProvider router={router} />
          </ReactQueryProvider>
        </TranslationsProvider>
      </CookiesProvider>
    </ErrorBoundary>
  </React.StrictMode>,
);
