import { createRoute } from "@tanstack/react-router";
import { Route as rootRoute } from "./root";
import { AlreadyDelayed } from "@/components/pages/already-delayed";
import { usePageTracking } from "@/lib/tracking";

export const Route = createRoute({
  getParentRoute: () => rootRoute,
  path: "/flow/delayed/$encodedActivityId/already-delayed",
  component: Page,
});

function Page() {
  const { encodedActivityId } = Route.useParams();

  usePageTracking(encodedActivityId, {
    type: "already_delayed",
    number: 3,
  });

  return <AlreadyDelayed />;
}
