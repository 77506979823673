import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { api } from "@lib/client";
import { type GenericReview } from "@lib/config/types";
import {
  processUgc,
  extractUploadBlock,
  deleteUgc,
} from "@lib/image-upload.client";
import { logger } from "@lib/logger.client";
import { type OrderItemDetails } from "@lib/review";
import type { Ticket } from "@lib/ticket";
import { type ReviewValidationResponse } from "@server/routes/api/validate";
import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect, useRef, useState } from "react";
import { type FieldValues, FormProvider, useForm } from "react-hook-form";
import { validate } from "uuid";
import { useNavigate } from "@tanstack/react-router";
import { DeleteImageProvider } from "@/lib/context";

type ReviewFormProps = {
  encodedActivityId: string;
  ticket: Ticket | undefined;
  children: React.ReactNode;
  currentStep: string;
  orderItemDetails?: OrderItemDetails;
  config: GenericReview;
};

export function ReviewForm({
  encodedActivityId,
  ticket,
  children,
  currentStep,
  orderItemDetails,
  config,
}: ReviewFormProps) {
  const methods = useForm();
  const [fingerprint, setFingerprint] = useState<string | null>(null);
  const blackBoxRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(window.location.search);
  const product = queryParams.get("product")?.split("?")[0];

  const uploadBlock = extractUploadBlock(
    Object.values(config.pages[Number(currentStep)])[0],
  );

  const deleteImage = useCallback(
    (fileName: string) => deleteUgc(encodedActivityId, "claim", fileName),
    [encodedActivityId],
  );

  useEffect(() => {
    const loadFingerprint = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      const visitorId = result.visitorId;

      setFingerprint(visitorId);
      methods.setValue("fingerprint", visitorId); // Set the fingerprint in the form state
    };

    loadFingerprint().catch((error) => {
      logger.error(error, "Failed to load fingerprint");
    });
  }, [methods]);

  const { mutateAsync } = useMutation({
    mutationFn: async (formData: FieldValues) => {
      const blackBox = (blackBoxRef && blackBoxRef.current?.value) ?? "";

      const ugc = formData["ugc"] as File[] | undefined;
      const { ugcMeta, processedImages } = await processUgc({
        methods,
        uploadBlock,
        encodedActivityId,
        flow: "review",
        id: orderItemDetails?.id ?? "",
        ugc,
      });

      if (processedImages) {
        formData["syndicator-question:userGeneratedContent"] = processedImages;
      }

      const res = await api.validate.review.$post({
        json: {
          encodedActivityId,
          ticket,
          formData: {
            ...formData,
            ugc: ugcMeta,
            blackBox,
          },

          currentStep: currentStep,
          orderDetails: orderItemDetails,
          product: product ? validate(product) : undefined,
        },
      });
      return res.json();
    },
    onError: (error) => {
      logger.error(error, "Error handling the mutation");
    },
    onSuccess: (data: ReviewValidationResponse) => {
      if (!data.success && "errors" in data) {
        Object.entries(data.errors ?? {}).forEach(([key, value]) => {
          if (typeof value === "string") {
            methods.setError(key, { message: value });
          } else if (value && typeof value === "object" && "message" in value) {
            methods.setError(key, {
              message: JSON.stringify(value),
            });
          }
        });
        return;
      }

      if ("redirect" in data && data.redirect) {
        // Forse a full reload, to ensure data is loaded
        void navigate({
          ...data.redirect,
          reloadDocument: true,
        });
        return;
      }

      if (data.success) {
        if (currentStep === "0") {
          const { fingerprint } = methods.getValues() as {
            fingerprint: string;
          };
          methods.reset({ fingerprint });
        }
      }
    },
  });

  async function onSubmit(formData: FieldValues) {
    try {
      await mutateAsync(formData);
    } catch (error) {
      logger.error(error, "Error handling review submission");
    }
  }

  return (
    <main>
      <DeleteImageProvider value={deleteImage}>
        <FormProvider {...methods}>
          <form onSubmit={(e) => void methods.handleSubmit(onSubmit)(e)}>
            <input
              ref={blackBoxRef}
              type="hidden"
              name="blackBox"
              id="ioBlackBox"
            />
            <input
              className="sr-only"
              {...methods.register("fingerprint")}
              id="fingerprint"
              value={fingerprint ?? ""}
              readOnly
            />
            {children}
          </form>
        </FormProvider>
      </DeleteImageProvider>
    </main>
  );
}
