import { createRoute } from "@tanstack/react-router";
import { LockedForm } from "@/components/forms/locked/form";
import { Route as rootRoute } from "./root";
import { usePageTracking } from "@/lib/tracking";

export const Route = createRoute({
  getParentRoute: () => rootRoute,
  component: RouteComponent,
  path: "/flow/$encodedActivityId/lock",
  loader: ({ params }) => params,
});

function RouteComponent() {
  const { encodedActivityId } = Route.useLoaderData();
  usePageTracking(encodedActivityId, {
    type: "lock",
    number: 0,
  });

  return <LockedForm encodedActivityId={encodedActivityId} />;
}
