import type { TitleBlockV2 } from "@lib/config/types";
import {
  alignStyle,
  type FontFamily,
  resolveFontFamilyVariables,
  textBlockWidth,
} from "@lib/utils";

type TitleProps = TitleBlockV2;

export function Title({ title }: TitleProps) {
  const fontFamily = title.font as FontFamily;

  return (
    <div
      data-block-type="Title"
      style={{
        ...alignStyle(title),
        maxWidth: textBlockWidth(title.width),
        ...resolveFontFamilyVariables(
          "--title",
          fontFamily,
          title.font_properties,
        ),
      }}
    >
      <h1
        className="font-[family-name:--title-font-name] font-[--title-font-weight]"
        style={{
          color: title.color,
          textAlign: title.alignment,
          fontSize: title.font_size,
          letterSpacing: title.letter_spacing,
          fontStyle: "var(--title-font-style)",
        }}
        dangerouslySetInnerHTML={{
          __html: title.text ?? "",
        }}
      />
    </div>
  );
}
