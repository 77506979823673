import { Card } from "@/components/ui/card";
import type { Activity } from "@lib/activity";
import { api } from "@lib/client";
import type { Ticket } from "@lib/ticket";
import { useMutation } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { Button } from "../components/ui/button";
import { RadioGroup, RadioGroupItem } from "../components/ui/radio-group";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useState, type CSSProperties } from "react";
import { logger } from "@lib/logger.client";
import { Input } from "../components/ui/input";
import { createRoute } from "@tanstack/react-router";
import { Route as rootRoute, getContext } from "./root";
import { usePageTracking } from "@/lib/tracking";

type DelayedProps = {
  encodedActivityId: string;
  activity: Activity;
  ticket?: Ticket;
};

export const Route = createRoute({
  getParentRoute: () => rootRoute,
  path: "/flow/delayed/$encodedActivityId",
  loader: getContext<DelayedProps>,
  component: function Page() {
    const props = Route.useLoaderData();
    return <Delayed {...props} />;
  },
});

// Define the form schema with Zod
const formSchema = z.object({
  reason: z.enum(
    ["still_using", "not_tried", "not_received", "broken", "other"],
    {
      required_error: "You need to select a reason.",
    },
  ),
  otherReason: z.string().optional(),
});

type FormData = z.infer<typeof formSchema>;

export function Delayed({ ticket, activity, encodedActivityId }: DelayedProps) {
  const [isSubmitted, setIsSubmitted] = useState(false);

  usePageTracking(
    encodedActivityId,
    !isSubmitted
      ? {
          type: "delayed",
          number: 1,
        }
      : {
          type: "completion",
          number: 2,
        },
  );

  return (
    <div className="flex flex-col items-center">
      <Card className="mt-40">
        {isSubmitted ? (
          <div className="text-center">
            <h2 className="text-lg font-semibold mb-4">Thank you!</h2>
            <p>Your response has been recorded.</p>
          </div>
        ) : (
          <DelayedForm
            encodedActivityId={encodedActivityId}
            setIsSubmitted={setIsSubmitted}
            ticket={ticket}
            activity={activity}
          />
        )}
      </Card>
    </div>
  );
}

function DelayedForm({
  ticket,
  setIsSubmitted,
}: {
  setIsSubmitted: (value: boolean) => void;
} & DelayedProps) {
  const form = useForm<FormData>({
    resolver: zodResolver(formSchema),
  });

  const { control, watch } = form;
  const { mutateAsync } = useMutation({
    mutationFn: async (formData: FormData) => {
      const reasonToSend =
        formData.reason === "other"
          ? `other: ${formData.otherReason}`
          : formData.reason;

      const res = await api.delayed.$post({
        json: {
          ticket,
          formData: { reason: reasonToSend ?? "" },
        },
      });

      return res.json();
    },
    onError: (error) => {
      logger.error(error, "Error handling the mutation");
    },
    onSuccess: (data) => {
      if (!data.success && "error" in data) {
        form.setError("reason", { message: data.error });
        return;
      }

      if (data.success) {
        setIsSubmitted(true);
      }
    },
  });

  // Handle form submission
  async function onSubmit(formData: FormData) {
    try {
      await mutateAsync(formData);
    } catch (error) {
      logger.error(error, "Error handling review submission");
    }
  }

  const reason = watch("reason") as string;
  return (
    <Form {...form}>
      <form
        className="grid w-full"
        onSubmit={(e) => void form.handleSubmit(onSubmit)(e)}
      >
        <FormField
          control={form.control}
          name="reason"
          render={({ field }) => (
            <FormItem className="space-y-3 mb-8">
              <FormLabel className="mb-4 block leading-normal">
                Why aren't you ready to leave feedback about your sample?
              </FormLabel>
              <FormControl>
                <RadioGroup onValueChange={field.onChange} value={field.value}>
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="still_using" />
                    </FormControl>
                    <FormLabel className="font-normal">
                      I'm still using it
                    </FormLabel>
                  </FormItem>

                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="not_tried" />
                    </FormControl>
                    <FormLabel className="font-normal">
                      I haven't tried it yet
                    </FormLabel>
                  </FormItem>

                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="not_received" />
                    </FormControl>
                    <FormLabel className="font-normal">
                      I haven't received it yet
                    </FormLabel>
                  </FormItem>

                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="broken" />
                    </FormControl>
                    <FormLabel className="font-normal">
                      It arrived broken
                    </FormLabel>
                  </FormItem>

                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="other" />
                    </FormControl>
                    <FormLabel className="font-normal">Other</FormLabel>
                  </FormItem>
                </RadioGroup>
              </FormControl>
              <FormControl>
                {reason === "other" && (
                  <FormItem className="mt-4">
                    <FormLabel className="block leading-normal">
                      Please specify:
                    </FormLabel>
                    <FormControl>
                      <Controller
                        name="otherReason"
                        control={control}
                        render={({ field }) => (
                          <Input
                            className="border"
                            style={
                              {
                                "--input-border-color": "currentColor",
                                "--field-border-size": "1px",
                              } as CSSProperties
                            }
                            {...field}
                          />
                        )}
                      />
                    </FormControl>
                  </FormItem>
                )}
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button type="submit">Submit</Button>
      </form>
    </Form>
  );
}
