"use client";

import { getTranslations, type Dictionary } from "@lib/i18n.client";
import type React from "react";
import { createContext, useContext } from "react";

export const TranslationsContext = createContext<Dictionary>({});

type ProviderProps = {
  children: React.ReactNode;
  dictionary: Dictionary;
};

export function TranslationsProvider({ children, dictionary }: ProviderProps) {
  return (
    <TranslationsContext.Provider value={dictionary}>
      {children}
    </TranslationsContext.Provider>
  );
}

export function useTranslations({
  skipValidation = false,
}: {
  skipValidation?: boolean;
} = {}) {
  const dictionary = useContext(TranslationsContext);
  if (!Object.keys(dictionary).length && !skipValidation) {
    throw new Error(
      "useTranslations must be used within TranslationsContext.Provider",
    );
  }

  // Provide a way to interrogate keys during development
  const showTranslationKeys = false;

  return getTranslations(dictionary, showTranslationKeys);
}
