import { createRoute } from "@tanstack/react-router";
import { Route as rootRoute, getContext } from "./root";
import { UnsubscribedPage } from "@/components/pages/unsubscribed";
import { usePageTracking } from "@/lib/tracking";

export type UnsubscribeProps = {
  encodedActivityId: string;
};

export const Route = createRoute({
  getParentRoute: () => rootRoute,
  loader: () => getContext<UnsubscribeProps>(),
  path: "/flow/unsubscribe/$shortOrderId/unsubscribed",
  component: Page,
});

function Page() {
  const { encodedActivityId } = Route.useLoaderData();
  usePageTracking(encodedActivityId, {
    type: "completion",
    number: 0,
  });

  return <UnsubscribedPage />;
}
