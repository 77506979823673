import { DoubleOptinForm } from "@/components/forms/double-optin";
import { Landing } from "@/components/pages/landing";
import type { Activity } from "@lib/activity";
import { type DoubleOptinPage } from "@lib/config/types";
import type { Config } from "@lib/config/utils";
import { createRoute } from "@tanstack/react-router";
import { Route as rootRoute, getContext } from "./root";
import { usePageTracking } from "@/lib/tracking";

export type DoubleOptinProps = {
  activity: Activity;
  config: Config;
  encodedActivityId: string;
  consentId: string;
};

export const Route = createRoute({
  getParentRoute: () => rootRoute,
  path: "/flow/double_optin/$encodedActivityId/$encodedConsentId",
  loader: ({ params }) => ({ ...getContext<DoubleOptinProps>(), ...params }),
  component: function Page() {
    const props = Route.useLoaderData();
    usePageTracking(props.encodedActivityId, {
      type: "double_optin",
      number: 1,
    });

    return <DoubleOptinPage {...props} />;
  },
});

export function DoubleOptinPage({
  activity,
  encodedActivityId,
  config,
  consentId,
}: DoubleOptinProps) {
  return (
    <DoubleOptinForm
      encodedActivityId={encodedActivityId}
      consentId={consentId}
    >
      <Landing
        activity={activity}
        config={config as DoubleOptinPage}
        page="landing"
      />
    </DoubleOptinForm>
  );
}
