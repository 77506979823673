const feedbackBlueprints = ["3.4", "3.5", "3.6", "3.8", "3.9", "3.10", "3.11"];

export function isSyndicated(
  blueprint: string | null | undefined,
  hideSyndicatorConsentBox: boolean,
): [string, string] | boolean {
  if (!blueprint || !feedbackBlueprints.includes(blueprint)) return false;
  switch (blueprint) {
    case "3.4":
    case "3.8":
      return [
        "BazaarVoice",
        "I agree that my email address, IP address and device fingerprint can be used by Bazaarvoice to verify the authenticity of my review. <a target='_blank' href='https://www.bazaarvoice.com/legal/privacy-policy/'>Read Bazaarvoice's Privacy Policy</a>",
      ];
    case "3.5":
    case "3.9":
      return [
        "Power Reviews",
        "I agree that my IP address and device fingerprint can be used by PowerReviews to verify the authenticity of my review. <a target='_blank' href='https://www.powerreviews.com/privacy-policy/'>Read PowerReviews' Privacy Policy</a>",
      ];
    case "3.6":
    case "3.10":
      return [
        "Yotpo",
        "I agree that my email address can be used by Yotpo to verify the authenticity of my review. <a target='_blank' href='https://www.yotpo.com/privacy-policy/'>Read Yotpo's Privacy Policy</a>",
      ];
    case "3.11":
      return hideSyndicatorConsentBox
        ? [
            "Review syndication partners - no checkbox",
            "By submitting this review, I agree that my email address, IP address and device ID can be used by third-party syndication partners to verify the authenticity of my review. <a target='_blank' href='https://www.sopost.com/privacy-notice/'>Read SoPost's Privacy Policy</a>",
          ]
        : [
            "Review syndication partners",
            "I agree that my email address, IP address and device ID can be used by third-party syndication partners to verify the authenticity of my review. <a target='_blank' href='https://www.sopost.com/privacy-notice/'>Read SoPost's Privacy Policy</a>",
          ];
    default:
      return false;
  }
}
